@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Benzin Semibold";
  src: url("./assets/static/fonts/PPNeueMachina-PlainUltrabold.otf");
}

@font-face {
  font-family: "Benzin Regular";
  src: url("./assets/static/fonts/PPNeueMachina-PlainUltrabold.otf");
}
@font-face {
  font-family: "Benzin Bold";
  src: url("./assets/static/fonts/PPNeueMachina-PlainUltrabold.otf");
}
@font-face {
  font-family: "Benzin Medium";
  src: url("./assets/static/fonts/PPNeueMachina-PlainUltrabold.otf");
}
@font-face {
  font-family: "fat";
  src: url("./assets/static/fonts/PPNeueMachina-PlainUltrabold.otf");
}
@font-face {
  font-family: "geom";
  src: url("./assets/static/fonts/PPNeueMachina-PlainUltrabold.otf");
}
@font-face {
  font-family: "file";
  src: url("./assets/static/fonts/PPNeueMachina-PlainUltrabold.otf");
}

:root{
  --bls-color-main-1: #ffffff;
  --bls-color-aux-1: #F8FAFC;
  --bls-color-main-aux-1: #ffffff;
  --bls-color-cpt-1: #94A3B3;
  --bls-color-bdr-1: #1e293b;
  --bls-color-bg-1: #0b141b;
  --bls-color-bg-hover-1: #00000044;
  --bls-color-bnt-txt-1: #0F172A;

  --bls-font-main-1: "fat";
  --bls-font-aux-1: "geom";

  --bls-font-sz-cpt-1: 1rem;
  --bls-font-sz-normal-1: calc(var(--bls-font-sz-cpt-1)*9/8);
  --bls-font-sz-main-1: calc(var(--bls-font-sz-cpt-1)*2);
  --bls-font-sz-aux-1: calc(var(--bls-font-sz-cpt-1)*15/8);
  --bls-font-sz-title-1: calc(var(--bls-font-sz-cpt-1)*8);
  --bls-font-sz-btn-cpt-1: calc(var(--bls-font-sz-cpt-1)*2);
  --bls-font-sz-nav-1: calc(var(--bls-font-sz-cpt-1)*9/8);

  
  --bls-color-main: var(--bls-color-main-1);
  --bls-color-aux: var(--bls-color-aux-1);
  --bls-color-main-aux: var(--bls-color-main-aux-1);
  --bls-color-cpt: var(--bls-color-cpt-1);
  --bls-color-bdr: var(--bls-color-bdr-1);
  --bls-color-bg: var(--bls-color-bg-1);
  --bls-color-bg-hover: var(--bls-color-bg-hover-1);
  --bls-color-bnt-txt: var(--bls-color-bnt-txt-1);

  --bls-font-main: var(--bls-font-main-1);
  --bls-font-aux: var(--bls-font-aux-1);
  
  --bls-font-sz-cpt: var(--bls-font-sz-cpt-1);
  --bls-font-sz-normal: var(--bls-font-sz-normal-1);
  --bls-font-sz-main: var(--bls-font-sz-main-1);
  --bls-font-sz-aux: var(--bls-font-sz-aux-1);
  --bls-font-sz-title: var(--bls-font-sz-title-1);
  --bls-font-sz-btn-cpt: var(--bls-font-sz-btn-cpt-1);
  --bls-font-sz-nav: var(--bls-font-sz-nav-1);


  --primary: hsl(60.24 98.43% 50.2%);

  
  --font-aux: "geom";

  --nft-name-color: var(--primary);
  --nft-name-font: "fat";
  --nft-name-font-1: "file";
  --nft-name-size: 2rem;
  
  --nft-act-font: "geom";

  --mint-page-border-color: var(--primary);
}

body {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  background-color: #111111;
  min-height: 100vh;
}


#root{
  min-height: 100vh;
}
.btn {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #eae9e9;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  font-family: var(--bls-font-aux);
  background: linear-gradient(90deg, #181919, #424141, #101010, #232525);
  background-size: 400%;
  border-radius: 30px;
  cursor: pointer;
  z-index: 0;
}
 
.btn:hover {
  animation: animate 8s linear infinite;
}
 
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
 
.btn:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #9cffe4, #bbf300, #62ff5a, #83fcf2);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
  cursor: pointer;
  
}
 
.btn:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

.btna {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #eae9e9;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  font-family: var(--bls-font-aux);
  background: linear-gradient(90deg, #181919, #424141, #101010, #232525);
  background-size: 400%;
  border-radius: 30px;
  cursor: pointer;
  z-index: 0;
}
 
.btna:hover {
  animation: animate 8s linear infinite;
}
 
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
 
.btna:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #ff4e80 , #ff2155 , #f0590d , #fc8783);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
  cursor: pointer;
  
}
 
.btna:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}
.normaltext{
  color: #f6f5f5;

}

.textShineBlack {
  background: linear-gradient(to right, #9cffe4 20%, #bbf300 30%, #ff915a 70%, #83fcf2 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 3s ease-in-out infinite alternate;
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}
.ns-main-sec {
  background-color: #17181b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ns-new-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  
}
.textShineBlacka {
  background: linear-gradient(to right, #caf1e6 20%, #0086f3 30%, #f10520 70%, #e1f508 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 3s ease-in-out infinite alternate;
}
.ns-new-container-sm {
  max-width: 650px;
  margin: 0 auto;
}

.ns-myFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ns-header-sp {
  padding: 1rem 0;
}
.ns-header-sp ul {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.ns-header-sp ul li a {
  text-decoration: none;
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-nav);
}
.ns-header-sp ul li a:hover {
  color: var(--bls-color-main-aux) !important;
}
.ns-header-btn,
.ns-header-btn-sec {
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  outline: none;
  border: none;
  background-color: var(--bls-color-main);
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-nav);
  color: var(--bls-color-bnt-txt);
  cursor: pointer;
  box-sizing: border-box;
}

/* Mobile Menus  */
header .ns-menus-btn {
  display: none;
}
.ns-hidden-on-desk {
  display: none;
}
header .ns-newlogo {
  max-width: 80px;
}
header .ns-mob-hidden.ns-active {
  font-family: var(--bls-font-aux);
  display: block;
  position: fixed;
  z-index: 10000;
  right: 0;
  top: 0;
  color: var(--bls-color-aux);
  width: 256px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  border-radius: 1rem;
}
.ns-menus-btn-close {
  position: absolute;
  top: 2.2rem;
  right: 1rem;
  background-color: var(--bls-color-main);
  font-size: 1.4rem;
  color: var(--bls-color-bnt-txt);
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}
/* Header end  */

/* HOme Page  */

/* Hero Section  */
.hero-bg {
  background: url(./assets/static/imgs/14.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  height: 100vh;

}
.heron-bg {
  background: url(./assets/static/imgs/01.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  height: 100vh;

}
.herona-bg {
  background: url(./assets/static/imgs/bigpic.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  height: 100vh;

}
.ns-hero-sec {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 5rem 0;
}
.ns-hero-left {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.ns-hero-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.ns-hero-right img {
  width: 100%;
  max-width: 375px;
  height: auto;
}
.ns-hero-left span {
  font-family: "Benzin Bold";
  color: #f7f7f7;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000000;
  font-size: var(--bls-font-sz-main);
}
.ns-hero-left h1 {
  padding: 0;
  margin: 0;
  max-width: 626px;
  
  font-family: var(--bls-font-main);
  font-size: 10rem;
  color: var(--bls-color-main);
   text-shadow: -2px -2px 0 var(--bls-color-bnt-txt), 2px -2px 0 var(--bls-color-bnt-txt), -2px 2px 0 var(--bls-color-bnt-txt), 2px 2px 0 var(--bls-color-bnt-txt); 
  
  
}

.ns-liear-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}
.ns-liear-bg img {
  width: 100%;
  max-width: 50px;
  margin-top: -2rem;
  height: auto;
  animation: bounce 2s infinite;
  padding: 1rem;
  /* background-color: var(--bls-color-bg-hover); */
  /* background: -webkit-linear-gradient(90deg, var(--bls-color-bg-hover) -30%, var(--bls-color-aux) 130%);
  border-radius: 30%; */
}
@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0;
	border-radius: 10px;
	overflow: hidden;

	
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 5px;
	}
}

header {
  background-color: #17181be5;
  /* border-top: 1px solid var(--bls-color-bdr); */
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/* End Hero Section  */

.ns-backs-sec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 3rem;
  padding: 5rem 0;
}
.ns-bs-right p {
  font-family: var(--bls-font-aux);
  color: var(--bls-color-aux);
  line-height: 1.85;
  margin: 1.75rem 0 2.5rem 0;
  font-size: var(--bls-font-sz-normal);
  background-color: var(--bls-color-bg-hover);
  padding: 0.7rem 0.5rem 0.7rem 1rem;
  border-radius: 1rem;
}
.ns-bs-right h3,
.ns-roadmap-left h3 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-main);
  color: var(--bls-color-main);  
  text-shadow: -2px -2px 0 var(--bls-color-bg), 2px -2px 0 var(--bls-color-bg), -2px 2px 0 var(--bls-color-bg), 2px 2px 0 var(--bls-color-bg);
  /* background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.ns-backs-sec img {
  max-width: 100%;
  height: auto;
}
.ns-bs-left {
  padding-left: 1rem;
}

/* Roadmap Sec  */
.ns-roadmap-sec {
  display: flex;
  padding: 5rem 0;
  align-items: center;
}
.ns-roadmap-left {
  width: 60%;
}
.ns-roadmap-right {
  width: 40%;
  max-width: 24%;
  margin: 0 auto;
}
.ns-roadmap-right img {
  max-width: 100%;
  height: auto;
}
.ns-roadmap-list {
  font-family: var(--bls-font-aux);
  margin-top: 1.3rem;
}
.ns-road-list-flex {
  display: flex;
  align-items: center;
  gap: 2.2rem;
}
.ns-roadmap-list span {
  display: inline-block;
  margin-top: 2.5rem;
  border: 1px solid var(--bls-color-bdr);
  border-radius: 8px;
  background-color: var(--bls-color-bg-hover);
  padding: 0.9rem 1.29rem;
  color: var(--bls-color-aux);
  font-size: var(--bls-font-sz-normal);
}

/* End Roadmap Sec  */
/* End HOme Page  */

/* Mint Page  */

.ns-mint-main-wrap {
  padding: 0 0 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
}

.ns-mint-sec {
  border-radius: 10px;
  width: 80%;
  border: 1px solid var(--bls-color-bdr);
  background: var(--bls-color-bg-hover);
  backdrop-filter: blur(8px);
  margin: 1.75rem 1rem 2.5rem 1rem;
  padding: 1.75rem 1rem 2.5rem 1rem;;
}
.ns-mint-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-aux);
}
.ns-mint-fields p {
  padding: 0;
  margin: 0;
}
.ns-mint-fields span {
  display: inline-block;
  border: 1px solid var(--bls-color-main-aux);
  margin-top: 0.5rem;
  border-radius: 9px;
  display: inline-block;
  padding: 0.5rem 1.24rem;
  min-width: 85px;
  text-align: center;
}
.ns-mint-heading-main {
  padding: 3rem 0 0 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-aux);
  /* background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  text-align: center;
  max-width: 80%;
  margin: 0rem auto 2rem auto;
}

.ns-mint-step-heading-main {
  box-sizing: border-box;
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  font-size: 2rem;
  text-align: center;
  max-width: 80%;
  text-shadow: -2px -2px 0 var(--bls-color-bg-hover), 2px -2px 0 var(--bls-color-bg-hover), -2px 2px 0 var(--bls-color-bg-hover), 2px 2px 0 var(--bls-color-bg-hover);
}

.ns-mint-heading-main > span {
  
  font-size: 3rem;
  background: linear-gradient(to right, #d6d6d6 20%, #f3f3f3 30%, #bbbbbb 70%, #e6dfdf 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 3s ease-in-out infinite alternate;
}

.ns-leaderboard-heading-state {
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-aux);
  text-align: center;

  /* background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin: 0 0 2rem 0;
}

.ns-leaderboard-table th, td {  
  border: 1px solid var(--bls-color-bdr);
  padding: 0.7rem 2rem;
  text-align: center;
}

.ns-leaderboard-table th {    
  color: var(--bls-color-cpt);
  font-size: var(--bls-font-sz-cpt);
  /* text-decoration: underline;
  text-decoration-color: var(--primary);
  text-decoration-thickness: 1px;
  text-underline-offset: 0.2rem; */
}

.ns-leaderboard-table {
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-normal);
  border-collapse: collapse;
  
   background-color: #000000e0; 
 background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  margin-bottom: 3rem;
  box-shadow:
    inset 0 0 50px #fff,      /* inner white */
    inset 20px 0 80px rgb(101, 250, 233),   /* inner left magenta short */
    inset -20px 0 80px rgb(1, 10, 10),  /* inner right cyan short */
    inset 20px 0 300px rgb(0, 0, 0),  /* inner left magenta broad */
    inset -20px 0 300px rgb(0, 0, 0), /* inner right cyan broad */
    0 0 50px #fff,            /* outer white */
    -10px 0 80px rgb(0, 0, 0),        /* outer left magenta */
    10px 0 80px #0ff; 
}
.textShineBlackleaderboard {
  background: linear-gradient(to right, #c1ffc7 20%, #f5fcde 30%, #fadecf 70%, #caffcf 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 3s ease-in-out infinite alternate;
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}

.textShineBlackleaderboarda {
  background: linear-gradient(to right, #edf7ee 20%, #fbfef1 30%, #dddad8 70%, #e0e6e1 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 3s ease-in-out infinite alternate;
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}
.ns-leaderboard-table-head {
  padding: 2rem;
}

.ns-leaderboard-table-body tr:hover {
  background-color: var(--bls-color-bg-hover);
}

.ns-mint-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1.75rem;
}
.ns-mint-heading h4 {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--bls-color-aux);
  font-family: "file";
  font-size: var(--bls-font-sz-nav);
  /* background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.ns-mint-heading h4::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--bls-color-main-aux);
  width: 100%;
}
.ns-mint-heading span {
  border: 1px solid var(--bls-color-main-aux);
  margin-top: 0.5rem;
  border-radius: 9px;
  display: inline-block;
  color: var(--bls-color-aux);
  padding: 0.5rem 1.24rem;
  font-size: var(--bls-font-sz-normal);
  font-family: var(--bls-font-aux);
}
.ns-mint-counter {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ns-mint-cb {
  margin-top: 1rem;
  display: flex;
}
.ns-mint-cb,
.mint-counter-img {
  overflow: hidden
}

.mint-counter-img {
  width: 165px;
  height: 165px;
  border-radius: 12px;
  overflow: hidden
}

.ns-mint-cb button {
  outline: none;
  border: none;
  font-family: var(--bls-font-aux);
  display: inline-block;
  border: 1px solid var(--bls-color-main-aux);
  border-radius: 9px;
  color: var(--bls-color-main);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bls-color-bg);
  font-size: var(--bls-font-sz-aux);
  text-align: center;
  cursor: pointer;
}
.ns-mint-cb button span {
  margin-top: -4px;
  display: inline-block;
}
.ns-mint-cb input {
  outline: none;
  border: none;
  width: 50px;
  text-align: center;
  font-size: var(--bls-font-sz-aux);
  font-family: var(--bls-font-aux);
  background-color: transparent;
  color: var(--bls-color-aux);
}

.ns-mint-btn-new {
  margin-top: 1.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ns-mint-btn-new button {
  border-radius: 100px;
  padding: 0.5rem 2.3rem;
  outline: none;
  border: none;
  color: var(--bls-color-bnt-txt);
  background-color: var(--bls-color-main);
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-btn-cpt);
  cursor: pointer;
  box-sizing: border-box;
}
/* End Mint Page  */

/* Staking Page  */
.ns-new-container-stacking {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 1rem;
}
.ns-stack-main-wrap {
  padding: 2rem 0 5rem 0;
}
.ns-stack-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.ns-stack-card {
  border-radius: 10px;
  border: 1px solid var(--bls-color-bdr);
  background: linear-gradient(0deg, #000000dc, #272727);
  padding: 1.75rem 1rem 2.5rem 1rem;
  animation: steam 20s linear infinite;
}
.ns-stack-card img {
  max-width: 100%;
  height: auto;
}
.ns-stack-card h4 {
  padding: 0;
  margin: 0 0 1rem 0;
  text-align: center;
  box-sizing: border-box;
  font-family: "file";
  font-size: var(--bls-font-sz-nav);
  color: var(--bls-color-aux);
  /* text-shadow: -1px -1px 0 var(--bls-color-main), 1px -1px 0 var(--bls-color-main), -1px 1px 0 var(--bls-color-main), 1px 1px 0 var(--bls-color-main); */
  /* background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.ns-nft-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ns-nft-type {
  padding: 0;
  margin: 0.5rem 0;
  display: inline-block;
  box-sizing: border-box;
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-main);
  color: #F8FAFC;
}

.ns-nft-status p {
  padding: 0;
  margin: 0;
  text-align: center;
  border: 1px solid #fff;
  margin-top: 0.3rem;
  border-radius: 9px;
  color: #fff;
  padding: 0.5rem 1.24rem;
  font-size: var(--bls-font-sz-aux);
  font-family: var(--font-aux);
}

.ns-stackbtn-new {
  margin-top: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ns-stack-nft-btn {
  border-radius: 100px;
  padding: 0.5rem 2.3rem;
  outline: none;
  border: none;
  background-color: var(--bls-color-main);
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-main);
  color: var(--bls-color-bnt-txt);
  cursor: pointer;
  box-sizing: border-box;
}
.ns-stak-details-containerbottom {
  display: grid;
  grid-template-columns: 1fr ;
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-normal);
  gap: 1.75rem;
  color: var(--bls-color-aux);
  padding-bottom: 3rem;
}
/* DEtail Section  */
.ns-stak-details-container {
  display: grid;
  grid-template-columns: 1fr 350px 1fr 1fr;
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-normal);
  gap: 1.75rem;
  color: var(--bls-color-aux);
  padding-bottom: 3rem;
}
.ns-detail-stake {
  border: 1px solid var(--bls-color-bdr);
  border-radius: 9px;
  padding: 0.85rem 0.75rem;
  background-color: var(--bls-color-bg-hover);
}
.ns-detail-flex-stake {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.ns-detail-stake img {
  max-width: 100%;
  height: auto;
}
.ns-details-logo {
  width: 45px;
}
.ns-detail-content {
  text-align: center;
  flex-grow: 1;
}
.ns-detail-stake span {
  display: block;
  text-align: center;
}
/* ENd DEtail Section  */
/* Work Page  */

.ns-flex-work {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
  gap: 1rem;
  box-sizing: border-box;
}
.ns-work-left {
  flex-basis: 46%;
}
.ns-work-right {
  flex-basis: 44%;
}
.ns-work-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.ns-work-right img {
  max-width: 100%;
  height: auto;
}
.ns-work-left h3,
.ns-team-wrapper h3 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-main);
  color: var(--bls-color-main);
 
}
.ns-work-left span,
.ns-work-right span {
  color: var(--bls-color-aux);
  font-family: "file";
  font-size: var(--bls-font-sz-normal);
  padding: 0;
  margin-top: 1.4rem;
  line-height: 1.8;
  box-sizing: border-box;
  
 
}
.ns-work-left p,
.ns-work-right p  {
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-normal);
  padding: 0;
  margin-top: 1.4rem;
  line-height: 1.8;
  box-sizing: border-box;
}
.ns-work-left div,
.ns-work-right div  {
  margin-top: 4rem;
  
  padding: 0.7rem 0.5rem 0.7rem 1rem;
  position: relative;
	z-index: 0;
	border-radius: 10px;
	overflow: hidden;

	
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #232323;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#232323, #212020), linear-gradient(#363636, #393737), linear-gradient(#131313, #1b1a1a), linear-gradient(#262323, #1f1b1b);
		animation: rotate 24s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: #212020;
		border-radius: 5px;
	}
}

/* How WOrks End  */

footer {
  background-color: var(--bls-color-bg-hover);
  color: var(--bls-color-aux);
}
.ns-footer-myFlex {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}
footer .ns-footer-logo {
  margin-top: 32px;
  max-width: 80px;
}
footer .ns-footer-links {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
footer .ns-footer-links h4 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "file";
  font-size: 1.2rem;
  
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--bls-color-main);
}
.ns-footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--bls-font-aux);
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

footer ul li a {
  color: var(--bls-color-aux);
  text-decoration: none;
}
footer ul li a:hover {
  color: var(--bls-color-main);
  text-decoration: underline;
  text-decoration-color: var(--bls-color-aux);
}
footer .ns-footer-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
footer .ns-footer-socials img {
  width: 40px;
}
footer .ns-copy-right {
  border-top: 1px solid var(--bls-color-bdr);
  border-bottom: 1px solid var(--bls-color-bdr);
  background-color: var(--bls-color-bg);
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
  font-family: var(--bls-font-aux);
}

/* Footer End  */

/* About Page  */
.ns-flex-team {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem;
  box-sizing: border-box;
}
.ns-team-wrapper {
  padding: 6rem 0;
}
.ns-team-left {
  /* flex-basis: 45%; */
  width: 100%;
  max-width: 500px;
  /* background-color: rgba(255, 68, 0, 0.247); */
}
.ns-team-right {
  /* background-color: aqua; */
  width: 100%;
  max-width: 500px;
}

.ns-member-card {
  position: relative;
}
.ns-member-card img {
  max-width: 100%;
  height: auto;
}
.ns-member {
  max-width: 350px;
  margin-top: 2rem;
}
.ns-member-2 {
  max-width: 300px;
  margin-top: 2rem;
}

.member-badge h5 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-aux);
  color: var(--bls-color-aux);  
  text-shadow: -2px -2px 0 var(--bls-color-bg-hover), 2px -2px 0 var(--bls-color-bg-hover), -2px 2px 0 var(--bls-color-bg-hover), 2px 2px 0 var(--bls-color-bg-hover);
}
.member-badge {
  position: absolute;
  bottom: 80px;
  right: 0%;
  transform: translateX(100%);
  padding: 0.5rem 1.25rem;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid var(--bls-color-main-aux);
  border-right: 1px solid var(--bls-color-main-aux);
  border-bottom: 1px solid var(--bls-color-main-aux);
  background-color: var(--bls-color-main-aux);
}
.ns-team-left p,
.ns-team-right p {
  font-size: var(--bls-font-sz-normal);
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  line-height: 1.75;
  background-color: var(--bls-color-bg-hover);
  padding: 0.7rem 0.5rem 0.7rem 1rem;
  border-radius: 1rem;
}
.ns-team-left p span,
.ns-team-right p span {
  font-size: var(--bls-font-sz-cpt);
  color: var(--bls-color-main-aux);
  font-family: "file";
  line-height: 1.75;
}

/* FAQs */
.ns-faq-container h5 {
  padding: 0;
  margin: 0;
}
.ns-faq-container {
  padding: 2rem 0 0 0;
  margin-top: 5rem;
}
.ns-faq-container .mb-4 {
  margin-bottom: 1rem;
  border: 1px solid var(--bls-color-bdr);
  border-radius: 8px;
  overflow: hidden;
}
.ns-faq-container .ns-faq-wrapper {
  margin-top: 3rem;
}
.ns-faq-container .ns-faq-question {
  display: flex;
  background-color: var(--bls-color-bg);
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  cursor: pointer;
  font-family: var(--bls-font-aux);
}
.ns-faq-container .ns-faq-question h5 {  
  color: var(--bls-color-cpt);
  font-size: var(--bls-font-sz-cpt);
}
.ns-faq-container img {
  max-width: 100%;
  height: auto;
  width: 16px;
  transition: all 0.3s ease-in-out;
}
.ns-faq-container h5 {
  font-size: 1rem;
  color: white;
  font-family: "Benzin Regular";
  font-weight: 400;
}
.ns-faq-container .ns-faq-answer {
  padding: 1.25rem;
  background-color: var(--bls-color-bg-hover);
}
.ns-faq-container .ns-faq-answer p {
  padding: 0;
  margin: 0;
  line-height: 1.8;
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-normal);
}
.ns-faq-container .ns-faq-heading {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--bls-color-main);
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-main);  
  text-shadow: -2px -2px 0 var(--bls-color-bg), 2px -2px 0 var(--bls-color-bg), -2px 2px 0 var(--bls-color-bg), 2px 2px 0 var(--bls-color-bg);
  /* background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

/* Staking Modal  */
.ns-stake-overlay {
  background-color: #11111193;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  box-sizing: border-box;
  overflow-y: auto;
}
.ns-modal-container {
  width: 100%;
  max-width: 1080px;
  border: 1px solid var(--bls-color-bdr);
  border-radius: 10px;
  background: rgba(11, 20, 27, 0.11);
  backdrop-filter: blur(8px);
  padding: 2rem 2rem 2rem 2rem;
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.ns-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  overflow-y: auto;
}
.ns-modal-heading-flex {
  display: flex;
  align-items: center;
}
.ns-modal-heading {
  flex-grow: 1;
  text-align: center;
}
.ns-modal-heading h3 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--bls-font-aux);
  font-size: var(--bls-font-sz-aux);
  color: var(--bls-color-aux);
  /* text-shadow: -1px -1px 0 var(--bls-color-main), 1px -1px 0 var(--bls-color-main), -1px 1px 0 var(--bls-color-main), 1px 1px 0 var(--bls-color-main); */
    /* background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin-left: 2.5rem;
}
.ns-modal-heading-flex button {
  outline: none;
  border: none;
  font-family: var(--bls-font-main);
  display: inline-block;
  border-radius: 9px;
  color: var(--bls-color-bnt-txt);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bls-color-main);
  /* background-color: transparent; */
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
}
.ns-modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ns-modal-btn button {
  border-radius: 100px;
  padding: 0.75rem 2.3rem;
  position: relative;
  display: inline-block;
  outline: none;
  border: none;
  background: linear-gradient(90deg, #181919, #424141, #101010, #232525);
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-btn-cpt);
  color: #eae9e9;
  cursor: pointer;
  min-width: none;
  box-sizing: border-box;
  border-radius: 30px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;

  


}
.ns-modal-nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: 20px;
  margin: 1rem 0;
}
.ns-modal-nft-grid-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Example box shadow */
  text-align: center;
}
.ns-stake-modal-card {
  border: 1px solid var(--bls-color-main-aux);
  border-radius: 8px;
  background-color: var(--bls-color-bg);
}
.ns-stake-modal-card span {
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-main);
  display: inline-block;
  padding: 0.5rem 0;
  color: var(--bls-color-aux);
}
.ns-stake-modal-card img {
  width: 100%;
}
.btnan {
  border-radius: 100px;
  padding: 0.75rem 2.3rem;
  outline: none;
  border: none;
  background-color: var(--bls-color-main);
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-btn-cpt);
  color: var(--bls-color-bnt-txt);
  cursor: pointer;
  min-width: none;
  box-sizing: border-box;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
 
.btnan:hover {
  animation: animate 8s linear infinite;
}
 
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
 
.btnan:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #fa0f0f, #f30049, #fa3434, #f80233);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
  cursor: pointer;
  
}
 
.btnan:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

.btnanclaim {
  border-radius: 100px;
  padding: 0.75rem 2.3rem;
  outline: none;
  border: none;
  background-color: var(--bls-color-main);
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-btn-cpt);
  color: var(--bls-color-bnt-txt);
  cursor: pointer;
  min-width: none;
  box-sizing: border-box;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
 
.btnanclaim:hover {
  animation: animate 8s linear infinite;
}
 
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
 
.btnanclaim:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #a6ff00, #00f38a, #1dc929, #90f631);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
  cursor: pointer;
  
}
 
.btnanclaim:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

.ns-stake-modal-card div span {
  color: var(--bls-color-aux);
  font-family: var(--bls-font-aux);
  /* font-size: var(--bls-font-sz-aux); */
  font-size: 1rem;
  padding: 0px;
}

.ns-stake-modal-card-select {
  /* border: 1px solid #ff0000; */
  border-radius: 8px;
  background-color: var(--bls-color-main);
}
.ns-stake-modal-card-select span {
  font-family: var(--bls-font-main);
  font-size: var(--bls-font-sz-main);
  display: inline-block;
  padding: 0.75rem 0;
  color: var(--bls-color-bnt-txt);
}
.ns-stake-modal-card-select img {
  width: 100%;
}

.ns-stake-modal-card-select div span {
  color: var(--bls-color-bnt-txt);
  font-family: var(--bls-font-aux);
  /* font-size: var(--bls-font-sz-aux); */
  font-size: 1.2rem;
  padding: 0px;
}

/* Media Queries  */

@media (max-width: 1180px) {
  /* Work Page Queries  */

  /* End Work Page Queries  */
}
@media (max-width: 1100px) {
  .ns-hidden-on-desk {
    display: block;
  }
  /* Staking Page  */
  .ns-stak-details-container {
    gap: 1rem;
    padding-bottom: 2.5rem;
  }
  /* End Staking Page  */

  
  /* HomePage Sec  */
  .ns-hero-left h1,
  .ns-hero-left span {
    font-size: 3rem;
  }
  .ns-hero-left h1 {
    font-size: 10rem;
  }
  /* End HomePage Sec  */
  /* Team Seection  */
  .ns-flex-team {
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  /* End Team Seection  */

  /* Work Page Queries  */
  .ns-work-left h3,
  .ns-team-wrapper h3 {
    font-size: 2rem;
  }
  .ns-work-left div {
    margin-top: 2rem;
  }
  .ns-work-right {
    align-items: center;
  }
  /* End Work Page Queries  */

  /* Header Queries  */
  .ns-header-sp ul {
    display: none;
    background-color: #000000e0;
  }

  .ns-header-btn {
    display: none;
  }
  header .ns-menus-btn {
    background-color: transparent;
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
  }
  /* ENd Header Queries  */
}
@media (max-width: 1024px) {
  /* Stacking Page  */
  .ns-stack-nft-btn {
    padding: 0.75rem 1.3rem;
    font-size: 2rem;
  }
  .ns-stak-details-container {
    display: grid;
    grid-template-columns: 1fr 300px 1fr;
  }
  .ns-details-logo {
    width: 36px;
  }
  /* End Stacking Page  */

  .ns-hero-left h1 {
    font-size: 8rem;
  }
  .ns-hero-right img {
    max-width: 70%;
  }
  .ns-hero-left {
    width: auto;
  }
  .ns-roadmap-list span {
    margin-top: 2rem;
    padding: 0.9rem 1rem;
    font-size: 1rem;
  }
  .ns-road-list-flex {
    gap: 1.85rem;
  }
}
@media (max-width: 768px) {
  /*LeaderBoard*/
  .ns-leaderboard-table th, td {  
    padding: 12px 16px;
  }
  /* Stacking Page  */
  .ns-stack-main-wrap {
    padding: 3rem 0;
  }
  .ns-stack-cards-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  .ns-stak-details-container {
    grid-template-columns: 1fr 300px;
  }
  .ns-detail-stake span {
    display: block;
    text-align: center;
    font-size: 1rem;
  }
  .ns-details-logo {
    width: 36px;
  }
  /* End Stacking Page  */

  /* MINT PAGE  */
  .ns-mint-btn-new button {
    font-size: 1.8rem;
  }
  .ns-mint-heading-main {
    font-size: 2.4rem;
  }
  .ns-mint-fields span {
    font-size: 1.5rem;
  }

  /* End MINT PAGE  */
  /* Home Page Queries  */
  .ns-liear-bg img {
    max-width: 36px;
  }
  .ns-hero-sec {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 2rem 0 3rem 0;
  }
  /* .ns-hero-left h1,
  .ns-hero-left span {
    font-size: 2rem;
  } */
  
  .ns-hero-left h1 {
    font-size: 6rem;
  }

  .ns-backs-sec {
    display: grid;
    grid-template-columns: 1fr;
    padding: 2rem 0;
  }
  .ns-bs-left {
    max-width: 500px;
    margin: 0 auto;
  }

  .ns-bs-left {
    padding-left: 0rem;
  }

  .ns-roadmap-sec {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
  }
  .ns-roadmap-left {
    width: 100%;
    margin-bottom: 2rem;
  }
  .ns-road-list-flex {
    display: block;
  }
  .ns-roadmap-right {
    width: 100%;
    max-width: 225px;
  }
  /* Home Page Queries End  */

  /* Team Seection  */
  .ns-member {
    max-width: 250px;
    margin-top: 2rem;
  }
  .ns-member-2 {
    max-width: 240px;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
  .ns-faq-container {
    padding: 1rem 0 0 0;
    margin-top: 3rem;
  }
  /* End Team Seection  */

  .ns-flex-work,
  .ns-team-wrapper {
    display: block;
    padding: 4rem 0;
  }
  .ns-work-right {
    flex-basis: 100%;
  }

  /* Header Queries  */
  header .ns-newlogo {
    max-width: 80px;
  }
  .ns-header-btn,
  .ns-header-btn-sec {
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
  }
  /* ENd Header Queries  */

  /* Footer Media Queries */
  footer .ns-footer-logo {
    flex-basis: 300px;
    max-width: 80px;
  }
  footer .ns-footer-logo {
    flex-basis: 280px;
    max-width: 80px;
  }
  .ns-footer-myFlex {
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
  }
  .ns-footer-desk {
    display: none !important;
  }
  footer .ns-footer-logo {
    margin-top: 0px;
    max-width: 80px;
  }
  /* End Footer Media Queries */
}
@media (max-width: 660px) {  
  /*LeaderBoard*/
  .ns-leaderboard-table th, td {  
    padding: 12px 16px;
  }
  .ns-leaderboard-table,
  .ns-leaderboard-table th {    
    font-size: 16px;
  }
}
@media (max-width: 580px) {
  .ns-mint-heading-main {
    font-size: 2rem;
  }
  .ns-mint-heading-main {
    max-width: 100%;
  }
  .ns-hero-sec {
    flex-direction: column-reverse;
  }
  .ns-hero-right {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  /*LeaderBoard*/
  .ns-leaderboard-table th, td {  
    padding: 10px 12px;
  }
  .ns-leaderboard-table,
  .ns-leaderboard-table th {    
    font-size: 14px;
  }
  /*End LeaderBoard*/
}
@media (max-width: 540px) {
  .ns-stak-details-container {
    grid-template-columns: 1fr auto;
  }
  .ns-details-logo {
    width: 22px;
  }

  .ns-hero-left h1 {
    font-size: 5rem;
  }

  /* Team Section  */
  .member-badge {
    padding: 0.5rem 0.5rem;
    bottom: 60px;
  }
  .member-badge h5 {
    font-size: 1rem;
  }
  /* End Team Section  */

  /* Header Queries  */
  header .ns-newlogo {
    max-width: 80px;
  }
  /* End Header Queries  */

  /* Footer Media Queries */
  .ns-footer-myFlex {
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }
  footer .ns-footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80px;
  }
  footer .ns-footer-links {
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
  }
  /* .ns-copy-right {
    padding: ;
  } */
  .ns-copy-right p {
    font-size: 0.85rem;
    padding: 0 1rem;
    margin: 0;
  }
  /* End Footer Media Queries */
  /*LeaderBoard*/
  .ns-leaderboard-table th, td {  
    padding: 8px 12px;
  }
  .ns-leaderboard-table,
  .ns-leaderboard-table th {    
    font-size: 12px;
  }
  /* End LeaderBoard */
}

@media (max-width: 480px) {
  /* Staking Modal  */
  .ns-modal-nft-grid {
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  }
  .ns-modal-container {
    padding: 2rem 1.25rem;
  }
  .ns-modal-heading h3 {
    font-size: 1.75rem;
    margin-left: 0;
  }
  .ns-modal-heading {
    text-align: left;
  }
  .ns-modal-heading-flex button {
    width: 32px;
    height: 32px;
  }
  /* End Staking Modal  */
  .ns-stak-details-container {
    grid-template-columns: 1fr;
  }
  .ns-detail-stake {
    padding: 0.55rem 0.45rem;
  }
  .ns-details-logo {
    width: 28px;
  }
  /* MINT PAGE  */
  .ns-mint-fields span {
    font-size: 1.2rem;
    padding: 0.75rem 1.24rem;
  }
  .ns-mint-main-wrap {
    padding: 0 0 6rem 0;
    min-height: 65vh;
  }

  /* Home Page  */
  /* .ns-hero-left h1,
  .ns-hero-left span {
    font-size: 1.75rem;
  } */
  .ns-hero-left h1 {
    font-size: 4rem;
  }
  .ns-hero-right {
    width: 80%;
    display: block;
    justify-content: start;
  }
  .ns-hero-right img {
    max-width: 100%;
  }
  ns-liear-bg {
    min-height: 70px;
  }
  /* Home Page  */

  .ns-member {
    max-width: 198px;
  }
  .ns-member-2 {
    max-width: 149px;
    margin-bottom: 2rem;
  }
  .member-badge {
    padding: 0.5rem 0.5rem;
    bottom: 45px;
  }
  .member-badge h5 {
    font-size: 0.7rem;
  }
  .ns-faq-container {
    margin-top: 1rem;
  }
  /* .ns-mint-fields {
    flex-direction: column;
  } */
  /* End Home Page */
  /*LeaderBoard*/
  .ns-leaderboard-table th, td {  
    padding: 5px 8px;
  }
  .ns-leaderboard-table,
  .ns-leaderboard-table th {    
    font-size: 11px;
  }
  /* End LeaderBoard */
}

@media (max-width: 400px) {
  /*Home page*/  
  .ns-hero-left span {
    font-size: 2.8rem;
  }
  .ns-hero-left h1 {
    font-size: 3.5rem;
  }
  /* End HomePage */
  /*LeaderBoard*/
  .ns-leaderboard-table th, td {  
    padding: 5px 8px;
  }
  .ns-leaderboard-table,
  .ns-leaderboard-table th {    
    font-size: 10px;
  }
  /* End LeaderBoard */
}
@media (max-width: 360px) {
  /*Home page*/  
  .ns-hero-left span {
    font-size: 2.4rem;
  }
  .ns-hero-left h1 {
    font-size: 3rem;
  }
  /* End HomePage */
  /*LeaderBoard*/  
  .ns-leaderboard-table th, td {  
    padding: 4px;
  }
  /* End LeaderBoard */
}
@media (max-width: 300px) {
  /*Home page*/  
  .ns-hero-left span {
    font-size: 2.2rem;
  }
  .ns-hero-left h1 {
    font-size: 2.8rem;
  }
}
@media (max-width: 300px) {
  /*Home page*/  
  .ns-hero-left span {
    font-size: 2rem;
  }
  .ns-hero-left h1 {
    font-size: 2.4rem;
  }
  /*End*/
  /*Footer*/  
  footer .ns-footer-logo {
    display: none;
    max-width: 80px;
  }
  /*End Footer*/
}
